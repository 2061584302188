<template>
  <div>
    <el-dialog
      v-model="open"
      width="50%"
      class="dialog-container"
      :show-close="false"
    >
      <template #header>
        <div class="dialog-header">
          <div class="close-button-wrapper">
            <button class="close-button" @click="$emit('cancel-dialog')">
              <el-icon><Close /></el-icon>
            </button>
          </div>
          <div class="title-wrapper text-center">
            <div class="title-main bold">
              {{ $t('pages.inventory.stocks.goods_in.dialog.title_1') }}
            </div>
            <div>
              {{ $t('pages.inventory.stocks.goods_in.dialog.title_2') }}
            </div>
          </div>
        </div>
      </template>
      <div
        class="multi-products-box"
        data-testid="multiple-products-dialog-box"
      >
        <div
          v-for="product in products"
          :key="product.id"
          class="multi-products-item"
          @click="$emit('select-product', product)"
        >
          <div class="item-details">
            <span class="bold">{{ product.name }}</span>
            <span
              >{{
                $t('pages.inventory.stocks.history.details.article_number')
              }}: {{ product.custom_id }}</span
            >
            <span
              >{{ $t('pages.inventory.stocks.history.details.barcode') }}:
              {{ product.barcode }}</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  filters: {
    empty(v) {
      return v || '--'
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    products: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    open: {
      get() {
        return this.visible
      },
      set(val) {
        if (!val) this.$emit('cancel-dialog')
      }
    }
  }
}
</script>

<style scoped>
.dialog-container :deep(.el-dialog) {
  min-width: 320px;
  max-width: 570px;
}

.dialog-header,
.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-main {
  margin-bottom: 10px;
}

.multi-products-box {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  max-height: 24.2rem;
  overflow: auto;
}

.multi-products-item {
  min-height: 6rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
}

.multi-products-item:hover {
  background-color: #ebf6fe;
}

.multi-products-item:nth-last-child(1) {
  border-bottom: unset;
}

.close-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.close-button {
  color: lightgray;
  background-color: unset;
  border: unset;
  font-size: 25px;
  cursor: pointer;
}

.close-button:hover {
  border: unset;
  color: lightgray;
  background-color: unset;
}

.bold {
  font-weight: bold;
}
</style>
